import React from 'react';
import Layout from '../components/Layout';
import {Link} from 'gatsby';

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <h3>Oups. Cette page n'exite pas !</h3>
        <p>Une erreur a dû se glisser dans votre url. Retournez à la page d'accueil pour retrouver votre chemin.</p>
          <Link to={'/'}>Page d'accueil</Link>
      </Layout>
    )
  }
}

export default NotFoundPage
